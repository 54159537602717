<script>
import { required, minLength, numeric } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";

export default {
  components: { validationMessages },

  data() {
    return {
      level: "",
      required_points: "",
      description: "",
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
    };
  },

  validations: {
    level: {
      required,
    },
    required_points: {
      required,
    },
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.resetForm()
    },

    async addSocialLevel() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      let formData = new FormData();
      formData.append("level", this.level);
      formData.append("required_points", this.required_points);
      formData.append("description", this.description);
      formData.append("csrf_token", this.csrf_token);

      try {
        const response = await Monitor.addSocialLevel(formData);
        if (response.error) {
          this.failedmsg("Failed to create Social Level");
        } else {
          this.successmsg("Social Level successfully created");
          this.closeModal();
          this.refreshData();
        }
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
        this.refreshData();

      }
    },

    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    refreshData() {
      this.$emit("onRefresh");
    },
    resetForm() {
      this.level = '';
      this.required_points = '';
      this.description = '';
      this.$v.$reset();
    },

  },
};
</script>

<template>
  <b-modal id="add_social_level" size="l" v-model="showModal" title="Social Level" title-class="font-18">
    <form @submit.prevent="addSocialLevel">
      <div class="row">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
            <b-card-text>
              <div class="row">
                <div class="col-sm-12">
                  <b-form-group label="Level" label-for="formrow-level-input" class="mb-3">
                    <b-form-input v-model.trim="level" :class="{ 'is-invalid': submitted && $v.level.$error }"></b-form-input>
                    <validationMessages v-if="submitted" :fieldName="'Level'" :validationName="$v.level"></validationMessages>
                  </b-form-group>

                  <b-form-group label="Required Points" label-for="formrow-required_points-input" class="mb-3">
                    <b-form-input v-model.trim="required_points" :class="{ 'is-invalid': submitted && $v.required_points.$error }"></b-form-input>
                    <validationMessages v-if="submitted" :fieldName="'Required Points'" :validationName="$v.required_points"></validationMessages>
                  </b-form-group>

                  <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
                    <b-form-input v-model.trim="description" ></b-form-input>
                  </b-form-group>


                  <input type="hidden" name="csrf_token" v-model="csrf_token">
                </div>
              </div>
            </b-card-text>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="addSocialLevel" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>
